export const companies = {
  create: {
    url: "admin/company/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "admin/company/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  get: {
    url: "admin/company/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  topratted: {
    url: "company/get/toprated",
    method: "GET",
    id: null,
    params: null,
  },
  getById: {
    url: "admin/company/get/",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "admin/company/delete/",
    method: "DELETE",
    id: null,
  },
  undelete: {
    url: "admin/company/undelete/",
    method: "PUT",
    id: null,
  },
  addMember: {
    url: "admin/company/AddMember",
    method: "POST",
    id: null,
    data: null,
  },
  updateMember: {
    url: "admin/company/updateMember/",
    method: "PUT",
    id: null,
    data: null,
  },
  deleteMember: {
    url: "admin/company/deleteMember/",
    method: "DELETE",
    id: null,
    data: null,
  },
  approve: {
    url: "company/approve/",
    method: "POST",
    id: null,
    data: undefined,
  },
  disapprove: {
    url: "company/disapprove/",
    method: "POST",
    id: null,
    data: undefined,
  },
  claims: {
    url: "admin/claim/getAll",
    method: "GET",
    id: null,
    data: undefined,
  },
  searchCompany: {
    url: "company/searchCompany",
    method: "GET",
    params: null,
  },
  createCertificate: {
    url: "admin/company/certificate/create",
    method: "POST",
    data: null,
  },
  updateCertificate: {
    url: "admin/company/certificate/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  deleteCertificate: {
    url: "admin/company/certificate/delete/",
    method: "DELETE",
    id: null,
    data: null,
  },
  updateTradeLicense: {
    url: "company/admin/updateTradeLicense",
    method: "PUT",
    data: null,
  },
  removeDocuments: {
    url: "company/admin/removeDocuments",
    method: "POST",
    data: null,
  },
  companyProject: {
    url: "project/get",
    method: "GET",
    data: null,
    params: null,
  },
  companyAwards: {
    url: "award/company/",
    method: "GET",
    data: null,
    params: null,
    id: null,
  },
  updateStatus: {
    url: "company/changeStatus/",
    method: "PUT",
    data: null,
    id: null,
  },
  adminGetAllCompanies:{
    url: "company/admin/AdmingetAllCompanies",
    method: "GET",
  }
};
