export const property_types = {
  getAll: {
    url: "admin/property_type/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "property_type/delete/",
    method: "DELETE",
  },
  get: {
    url: "property_type/get/",
    method: "GET",
  },

  create: {
    url: "property_type/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "property_type/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  getAllWithoutPagination: {
    url: "admin/nopaginate/property_type/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  sub_property: {
    get: {
      url: "sub_property_type/",
      method: "GET",
      params: null,
    },
    create: {
      url: "sub_property_type/",
      method: "POST",
      data: null,
    },
    update: {
      url: "sub_property_type/",
      method: "PUT",
      id: null,
      data: null,
    },
    delete: {
      url: "sub_property_type/",
      method: "DELETE",
    },
  },
};
