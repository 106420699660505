<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { companies } from "@/config/api/companies";
import { property_types } from "@/config/api/property_types";
import SubPropertyModal from "@/components/sub-property-type/sub-property-modal";
export default {


    components: {
        Layout,
        PageHeader,
        SubPropertyModal
    },
    data() {
        return {
            title: "Sub Property Info",
            subpropertyInfo: [],
            loading: false,
            tabIndex: 0,
            filter: null,
            filterOn: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: false, label: "Name", thStyle: { width: "30%" } },
                { key: "name_ar", sortable: false, label: "Name Ar" },
                { key: "active", sortable: false },
                { key: "actions", sortable: false },
            ],
            items: [
                {
                    text: "ARCHITECTURE STYLE",
                    href: 'property-types'
                },
                {
                    text: "SubProperty Info",
                    active: true,
                },
            ],
            currentProperty: null,
            selectedSubProperty: null
        };
    },
    async created() {
        console.log(this.$route.query.id)

        if (this.$route.query.id) {
            await this.loadInfo();
            this.loading = false;
        }
    },
    computed: {

    },
    methods: {

        showActionModal() {
            this.$bvModal.show("action-SubProperty");
        },
        async loadInfo() {

            if (this.$route.query.id) {
                const api = property_types.sub_property.get;
                api.params = { main_property: this.$route.query.id };
                this.loading = true;
                try {
                    var res = await this.generateAPI(api);
                    this.subpropertyInfo = res.data.sub_properties;

                } catch (error) {
                    console.error(error.response.data);
                    this.$bvToast.toast("Could not get property details!", {
                        title: "Failed",
                        variant: "danger",
                        solid: true,
                    });
                    let currentUser = JSON.parse(localStorage.getItem("user"));
                    console.log(currentUser);

                    setTimeout(() => {
                        this.$router.push({
                            path:
                                "property-types",
                        });
                    }, 3000);
                }
                this.loading = false;

            }
        },

        hideActionModal() {
            this.$bvModal.hide("action-SubProperty");
            this.loadInfo();

        },
        addSubProperty(currentProperty, selectedSubProperty) {
            this.currentProperty = currentProperty;
            this.selectedSubProperty = selectedSubProperty;

            this.showActionModal();
        },
        deleteItem(id) {
            let api = { ...property_types.sub_property.delete };
            api.url += id;
            this.generateAPI(api)
                .then((res) => {
                    this.loadInfo();
                    console.log(res);
                    this.$bvToast.toast("SubProperty has been deleted!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((err) => {
                    this.$bvToast.toast("Could not delete SubProperty!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.log(err);
                });
        },


    },
    watch: {},
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="loading">
            <div style="text-align: center" class="mt-5 mb-5">
                <pulse-loader color="#505d69" :loading="loading"></pulse-loader>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="">

                            <div class="product-desc">

                                <div style="text-align: end; float-right" class="mb-2 mb-2">

                                    <b-button @click="addSubProperty($route.query.item)" pill variant="primary">
                                        Add +
                                    </b-button>
                                </div>

                                <div>
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search"
                                                class="form-control form-control-sm ml-2">
                                            </b-form-input>
                                        </label>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <b-table class="table-centered" :items="subpropertyInfo" :fields="fields"
                                        responsive="sm" :busy="$store.state.api.loading" :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                        show-empty>
                                        <template #table-busy>
                                            <div style="text-align: center" class="mt-5 mb-5">
                                                <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                                            </div>
                                        </template>

                                        <template v-slot:cell(name)="row">
                                            {{ row.value }}
                                        </template>
                                        <template v-slot:cell(name_ar)="row">
                                            {{ row.item.name_ar|| "-" }}
                                        </template>

                                        <template #cell(active)="row">
                                            {{ row.item.deletedAt ? "Hidden" : "Active" }}
                                        </template>
                                        <template #cell(actions)="row">
                                            <button class="action-btn" @click="deleteItem(row.item._id)">
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                            <button class="action-btn" @click="
                                              addSubProperty($route.query.item, row.item)
                                            ">
                                                <i class="ri-edit-line"></i>
                                            </button>
                                        </template>
                                    </b-table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SubPropertyModal :currentProperty="currentProperty" :selectedSubProperty="selectedSubProperty"
            v-on:closeModal="hideActionModal" />
    </Layout>
</template>


